import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl
} from "reactstrap";
import Img from "gatsby-image";

const PropertyCarousel = props => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === props.items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? props.items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = props.items.map((item, i) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={`${i}${item.src}`}
      >
        <Img
          className="header__image header--fullwidth"
          fluid={item.image.imageFile.childImageSharp.fluid}
          alt={item.altText}
        />
      </CarouselItem>
    );
  });

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous} className="portfolio__template_carousel">
      {slides}
      <Container fluid={true} className="px-0">
        <Row style={{marginTop: `-34px`}}>
        <Col sm={{ size: 6 }} className="p-0 text-right d-none d-sm-block">
        <div className="counter">{activeIndex+1} / {props.items.length}</div>
        </Col>
          <Col sm={{ size: 6 }} className="pr-0">
          <div className="portfolio_carousel_controls">
  
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />            
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
            </div>
          </Col>
        </Row>
      </Container>
    </Carousel>
  );
};

export default PropertyCarousel
